<template>
  <v-card>
    <v-card-title class="pa-3">
      {{ $t('restaurantWidget') }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="4">
            <v-form>
              <UiField
                v-for="field in uiFields"
                :value="field"
                :key="field.key"
                class="py-2"
                @change="updateFieldValue"
              />
            </v-form>
          </v-col>
          <v-col cols="8">
            <iframe
              :title="$t('restaurantWidgetPreview')"
              :src="previewURL"
              width="100%"
              style="border: 1px solid #ccc"
              height="600px"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="error"
        large
        @click="close"
        class="px-8"
      >
        {{ $t('close') }}
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="type === 'create'"
        color="primary"
        large
        @click="createReservationWidgets"
        class="px-8"
      >
        {{ $t('create') }}
      </v-btn>
      <v-btn
        v-else
        color="primary"
        large
        @click="updateReservationWidgets"
        class="px-8"
      >
        {{ $t('update') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UiField from "@/components/uiField"

export default {
  name: "EditReservationWidgetForm",
  components: {
    UiField
  },
  props: {
    /**
     * Reservation widget to edit
     * @example { uuid, label, channel, location, item, defaultLanguage, darkTheme, active }
     */
    reservationWidget: {
      type: Object,
      default: null
    },
    /**
     * Reservation widget to edit
     * @example create, update
     */
    type: {
      type: String,
      default: 'create'
    }
  },
  data: () => ({
    storedReservationWidget: null,
    newReservationWidget: {
      label: '',
      channel: '',
      location: '',
      item: '',
      defaultLanguage: 'en',
      darkTheme: false,
      active: true
    }
  }),
  methods: {
    updateReservationWidgets() {
      this.$store.dispatch('updateReservationWidget', this.storedReservationWidget).then(res => {
        if(res !== null) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('reservationWidgetSuccessfullyUpdated')
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('somethingWentWrong')
          })
        }
      })
    },
    createReservationWidgets() {
      this.$store.dispatch('createReservationWidget', this.storedReservationWidget).then(res => {
        if(res !== null) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('reservationWidgetSuccessfullyUpdated')
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('somethingWentWrong')
          })
        }
      })
    },
    updateFieldValue(e) {
      const uiFields = this.uiFields
      const foundIndex = uiFields.findIndex(f => {
        return f.key = e.field.key
      })
      if(foundIndex !== -1) {
        uiFields[foundIndex].value = e.value
        this.uiFields = uiFields
      }
    },
    close() {
      this.$emit('close')
    }
  },
  computed: {
    uiFields: {
      get() {
        const storedReservationWidget = this.storedReservationWidget

        if(storedReservationWidget !== null && typeof storedReservationWidget.channel === 'object' && typeof storedReservationWidget.channel.uuid === 'string') {
          storedReservationWidget.channel = storedReservationWidget.channel.uuid
        }

        if(storedReservationWidget !== null && typeof storedReservationWidget.location === 'object' && typeof storedReservationWidget.location.uuid === 'string') {
          storedReservationWidget.location = storedReservationWidget.location.uuid
        }

        if(storedReservationWidget !== null && typeof storedReservationWidget.item === 'object' && typeof storedReservationWidget.item.uuid === 'string') {
          storedReservationWidget.item = storedReservationWidget.item.uuid
        }

        return [
          {
            key: 'label',
            type: 'text',
            label: this.$t('label'),
            hideDetails: true,
            value: storedReservationWidget.label
          },
          {
            key: 'channel',
            type: 'select',
            label: this.$t('channel'),
            hideDetails: true,
            value: storedReservationWidget.channel,
            options: this.allChannels
          },
          {
            key: 'location',
            type: 'select',
            label: this.$t('location'),
            hideDetails: true,
            value: storedReservationWidget.location,
            options: this.allLocations
          },
          {
            key: 'item',
            type: 'select',
            label: this.$t('item'),
            hideDetails: true,
            value: storedReservationWidget.item,
            options: this.allItems
          },
          {
            key: 'defaultLanguage',
            type: 'select',
            label: this.$t('defaultLanguage'),
            hideDetails: true,
            value: storedReservationWidget.defaultLanguage,
            options: this.languages
          },
          {
            key: 'darkTheme',
            type: 'switch',
            label: this.$t('darkTheme'),
            value: storedReservationWidget.darkTheme
          },
          {
            key: 'active',
            type: 'switch',
            label: this.$t('active'),
            value: storedReservationWidget.active
          }
        ]
      },
      set(newValue) {
        newValue.forEach(field => {
          this.storedReservationWidget[field.key] = field.value
        })
      }
    },
    previewURL() {
      return 'https://reservations.salescloud.is/?organization=' + this.organization.uuid + '&channel=' + this.storedReservationWidget.channel + '&l=' + this.storedReservationWidget.location + '&item=' + this.storedReservationWidget.item + '&dark=' + this.storedReservationWidget.darkTheme + '&defaultLanguage=' + this.storedReservationWidget.defaultLanguage
    },
    organization() {
      return this.$store.state.organization
    },
    allChannels() {
      return this.$store.state.channels.map(i => ({ label: i.label, key: i.uuid }))
    },
    allLocations() {
      return this.$store.state.locations.map(i => ({ label: i.label, key: i.uuid }))
    },
    allItems() {
      return this.$store.state.items.filter(i => i.type === 'event').map(i => ({ label: i.title, key: i.uuid }))
    },
    languages() {
      return this.$store.state.languages.map(i => ({ label: i.name, key: i.key }))
    }
  },
  watch: {
    reservationWidget(newValue) {
      if(this.reservationWidget !== null) {
        this.storedReservationWidget = JSON.parse(JSON.stringify(newValue))
      } else {
        this.storedReservationWidget = JSON.parse(JSON.stringify(this.newReservationWidget))
      }
    }
  },
  created() {
    if(this.reservationWidget !== null && this.type !== 'create') {
      this.storedReservationWidget = JSON.parse(JSON.stringify(this.reservationWidget))
    } else {
      this.storedReservationWidget = JSON.parse(JSON.stringify(this.newReservationWidget))
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card flat>
    <v-list>
      <v-list-item
        v-for="reservationWidget in reservationWidgets"
        :key="reservationWidget.uuid"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ reservationWidget.label }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ reservationWidget.uuid }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            @click="editReservationWidget(reservationWidget)"
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <div class="text-center py-2">
      <v-btn
        color="primary"
        large
        @click="createNewReservationWidget"
        class="px-8"
      >
        {{ $t('newWidget') }}
      </v-btn>
    </div>
    <v-dialog
      v-model="showEditDialog"
      max-width="800"
      scrollable
    >
      <EditReservationWidgetForm
        :reservation-widget="selectedReservationWidget"
        :type="dialogType"
        @close="closeEditDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import EditReservationWidgetForm from "@/components/booking/reservationWidget/EditReservationWidgetForm"
export default {
  name: "ReservationWidgetsList",
  components: {
	  EditReservationWidgetForm
  },
  data: () => ({
    showEditDialog: false,
    reservationWidgets: null,
    selectedReservationWidget: null,
    dialogType: 'update'
  }),
  methods: {
    editReservationWidget(widget) {
      this.dialogType = 'update'
      this.showEditDialog = true
      this.selectedReservationWidget = widget
    },
    createNewReservationWidget() {
      this.dialogType = 'create'
      this.showEditDialog = true
      this.selectedReservationWidget = null
    },
    getReservationWidgets() {
      this.$store.dispatch('getReservationWidgets').then(res => {
        if(Array.isArray(res) && res.length > 0) {
          this.reservationWidgets = res
        } else {
          this.reservationWidgets = []
        }
      })
    },
    closeEditDialog() {
      this.getReservationWidgets()
      this.showEditDialog = false
      this.selectedReservationWidget = null
    }
  },
  created() {
    this.getReservationWidgets()
  }
}
</script>

<style scoped>

</style>
